import * as React from 'react';
import './ARCIdentifierInput.scss';
import {Tooltip} from "@material-ui/core";
import INFO_ICON from "../../../../assets/img/Icon_Material_Info.svg";

type ARCIdentifierInputProps = {
    id: string,
    size: 'sm' | 'md' | 'lg',
    label?: string,
    placeholder?: string,
    required: boolean,
    disabled?: boolean;
    value: string | undefined,
    onChange: (event: string) => void,
    validate?: (val: any) => string | undefined,
    className?: string,
    tooltip?: string,
    prefix?: string
}

export const ARCIdentifierInput:React.ComponentType<ARCIdentifierInputProps> = (props) => {

    const [inputError, setInputError] = React.useState<string | undefined>(undefined);

    const renderValue = React.useMemo(() => {
        if (props.prefix && props.value && props.value.toUpperCase().startsWith(props.prefix.toUpperCase())) {
            return props.value?.substring(props.prefix.length);
        }

        return props.value;
    }, [props.prefix, props.value]);

    const handleEditableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange((props.prefix ?? '') + event.target.value);
    };

    return (
        <div className={`o-text-identifier-input__container ${props.className ? props.className : ''}`}>
            {props.label &&
            <label className={`o-text-identifier-input__label o-body-text--m`}>
                {props.label}

                {props.required &&
                <div className={'required-star'}>
                    {'*'}
                </div>
                }
                {props.tooltip &&
                <Tooltip placement={'bottom-start'} title={props.tooltip}>
                    <img src={INFO_ICON} alt={'Info Icon'}/>
                </Tooltip>
                }
            </label>
            }
            <div className={'u-display--flex'}>

                {props.prefix &&
                    <div className={`o-text-identifier-input__prefix ${props.disabled ? 'disabled' : ''} o-text-identifier-input__prefix--${props.size} ${inputError ? 'o-input__error' : ''} o-body-text--${props.size === 'lg' || props.size === 'md' ? 'l' : 'm'} u-letter-space-2xs`}>
                        {props.prefix}
                    </div>
                }

                <input
                    id={props.id}
                    type={'text'}
                    disabled={props.disabled}
                    placeholder={props.placeholder ?? ''}
                    className={`o-text-identifier-input ${props.prefix ? 'prefixed' : ''} o-text-identifier-input--${props.size} ${inputError ? 'o-input__error' : ''} o-body-text--${props.size === 'lg' || props.size === 'md' ? 'l' : 'm'} u-letter-space-2xs`}
                    value={renderValue}
                    onChange={(event) => {
                        handleEditableChange(event)

                        if(props.validate) {
                            setInputError(props.validate(event.target.value));
                        }
                    }}
                />
            </div>
            {inputError &&
            <div className={'o-text-identifier-input__error'}>
                {inputError}
            </div>
            }


        </div>
    )
}