import {DeviceDto, DeviceValidationKeys} from "../../../../dtos/Device";
import * as React from "react";
import {Dispatch, SetStateAction} from "react";
import ErrorWrapper, {ErrorWrapperProps} from "../../../ui-components/ErrorWrapper";
import {CreateDeviceChildComponentNames} from "../../pages/CreateDevice";
import {getLocalizedString} from "../../../../util/Localization";
import {isAlphanumeric} from "../../../../util/TextUtils";
import {ARCButton} from "../../../ui-components/design-system/ARCButton";
import {ARCIdentifierInput} from "../../../ui-components/design-system/identifier-input/ARCIdentifierInput";

type DeviceTagProps = {
    formDevice: DeviceDto,
    setFormDevice: Dispatch<SetStateAction<DeviceDto>>,
    goForward: (name: string) => void,
    fieldErrors: {[fieldName: string]: {
        error: string | undefined,
        shown: boolean,
    }},
    createErrorProps: (field: string) => ErrorWrapperProps,
    setFieldError: (field: string, error: string) => void,
    clearFieldError: (field: string) => void,
    validate: (value: string) => string,
}

export const DeviceTag:React.ComponentType<DeviceTagProps> = (props) => {
    const {formDevice, 
            setFormDevice, 
            goForward,
            fieldErrors,
            createErrorProps,
            setFieldError,
            clearFieldError,
            validate
    } = props;
            
    const onNext = React.useCallback(() => {
        if (formDevice.deviceTag && formDevice.deviceTag.length > 0) {
            goForward(CreateDeviceChildComponentNames.createDeviceForm)
        }
    }, [formDevice.deviceTag, goForward]) 
    
    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                onNext();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onNext]);

    return (
        <div className={'createdevice-other-div'}>
            <ErrorWrapper {...createErrorProps(`${DeviceValidationKeys.deviceTag}`)}>
                <div className={'editdevice-input-div'}>
                    <ARCIdentifierInput
                        id={'add-device-device-tag-input'}
                        size={'md'}
                        required={true} 
                        value={formDevice.deviceTag}
                        label={getLocalizedString('createDevice.deviceTag', 'Device Serial Number')}
                        tooltip={getLocalizedString('createDevice.deviceTag.tooltip', 'This is the unique identifier associated with the device.')}
                        onChange={(event) => {
                            const error = validate(event);

                            if (error) {
                                const showCharacter = !isAlphanumeric(event) && (fieldErrors[`${DeviceValidationKeys.deviceTag}`]?.error?.length ?? 0) === 0;
                                if (showCharacter) {
                                    setFormDevice({
                                        ...formDevice,
                                        deviceTag: event
                                    })
                                }
                                setFieldError(DeviceValidationKeys.deviceTag, error);
                            } else {
                                setFormDevice({
                                    ...formDevice,
                                    deviceTag: event
                                })
                                clearFieldError(DeviceValidationKeys.deviceTag);
                            }
                        }}
                    />
                </div>
            </ErrorWrapper>
            <div className={'createdevice-save-button-div'}>
                <ARCButton
                    id="device-id-next"
                    variant={'primary'}
                    size={'md'}
                    fill={'filled'}
                    onClick={() => {
                    goForward(CreateDeviceChildComponentNames.createDeviceForm)
                }}
                    label={getLocalizedString('next', 'Next')}
                    disabled={!formDevice.deviceTag || formDevice.deviceTag.length === 0}
                >
                </ARCButton>
            </div>
            
        </div>
    )
}
