import {Button} from "@material-ui/core";
import ErrorWrapper, {ErrorWrapperProps} from "../../../ui-components/ErrorWrapper";
import {
    calculateResponsiveSizeWithMinMax,
    isAlphanumeric
} from "../../../../util/TextUtils";
import * as React from "react";
import {DEV_STATUS_PENDING, DeviceDto, DeviceValidationKeys} from "../../../../dtos/Device";
import {useAppSelector} from "../../../../store/hooks";
import {appContextSelector} from "../../../app-context/appContextSlice";
import {Dispatch, SetStateAction} from "react";
import {deviceTypeSelector} from "../../deviceTypeSlice";
import {styled} from "@material-ui/core/styles";
import {DeviceDialog} from "../DeviceDialog";
import {getLocalizedString} from "../../../../util/Localization";
import {ARCButton} from "../../../ui-components/design-system/ARCButton";
import {ARCSelectInput} from "../../../ui-components/design-system/ARCSelectInput";
import {Option} from "../../../ui-components/ColorSelect";
import {ARCIdentifierInput} from "../../../ui-components/design-system/identifier-input/ARCIdentifierInput";

type CreateDeviceFormProps = {
    formDevice: DeviceDto,
    setFormDevice: Dispatch<SetStateAction<DeviceDto>>,
    fieldErrors: {[fieldName: string]: {
        error: string | undefined,
        shown: boolean,
    }},
    validateDeviceIdNumber: (input: string) => string,
    validateDeviceTag: (input: string) => string,
    createErrorProps: (field: string) => ErrorWrapperProps,
    setFieldError: (field: string, error: string) => void,
    clearFieldError: (field: string) => void,
    lockDeviceIdNumber: (input: string) => string | undefined,
    deviceIdTooltip: string
}

export const CreateDeviceForm:React.ComponentType<CreateDeviceFormProps> = (props) => {
    const context = useAppSelector(appContextSelector);
    const deviceTypeContext = useAppSelector(deviceTypeSelector);
    const {formDevice, 
            setFormDevice, 
            fieldErrors,
            validateDeviceIdNumber,
            validateDeviceTag,
            createErrorProps,
            setFieldError,
            clearFieldError,
            lockDeviceIdNumber,
            deviceIdTooltip} = props;

    const serialIsDeviceId = React.useMemo(() => {
        if (context.currentLocation?.serialIsDevice) {
            if (deviceTypeContext.deviceTypes.length > 0 && !formDevice.deviceType?.serialIsDevice) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }, [context.currentLocation, deviceTypeContext.deviceTypes, formDevice.deviceType])
    
    const [editableDevice, setEditableDevice] = React.useState<DeviceDto>({
        deviceId: undefined,
        deviceTag: '',
        deviceIdNumber: '',
        status: DEV_STATUS_PENDING,
        locationId: undefined,
        inactiveReason: '',
        inactiveReasonDetail: '',
        deviceType: undefined
    });

    const DeviceTypeOptions = React.useMemo(() => {
        let options : Option[] = deviceTypeContext.deviceTypes.map(deviceType => {
            return {
                value: deviceType.deviceTypeId,
                label: deviceType.deviceTypeName
            }
        });

        return [{value: undefined, label: getLocalizedString('createDevice.selectDeviceType', 'Select Device Type')}, ...options];
    }, [deviceTypeContext.deviceTypes]);
    
    React.useEffect(() => {
        if(formDevice) {
            setEditableDevice(formDevice);
        }
    }, [formDevice]);
    
    const [showSerialIsDeviceDialog, setShowSerialIsDeviceDialog] = React.useState<boolean>(false);
    
    const [deviceIdNumberLocked, setDeviceIdNumberLocked] = React.useState<boolean>(true);
    const [deviceTagLocked, setDeviceTagLocked] = React.useState<boolean>(true);
    const [deviceTypeLocked, setDeviceTypeLocked] = React.useState<boolean>(true);
    
    const showDeviceTagEdit = React.useMemo(() => {
        if (deviceTypeLocked && deviceIdNumberLocked) {
            return true;
        }
    }, [deviceTypeLocked, deviceIdNumberLocked]);
    
    const showDeviceTypeEdit = React.useMemo(() => {
        if (deviceTagLocked && deviceIdNumberLocked) {
            return true;
        }
    }, [deviceTagLocked, deviceIdNumberLocked]);
    
    const showDeviceIdEdit = React.useMemo(() => {
        if (deviceTagLocked && deviceTypeLocked) {
            return true;
        }
    }, [deviceTagLocked, deviceTypeLocked]);
    
    const deviceIdSaveDisabled = React.useMemo(() => {
        let deviceIdNumber = editableDevice.deviceIdNumber;
        let prefix = context.currentBrand?.deviceNumberPrefix; 
        if (prefix && deviceIdNumber === prefix) {
            return true; 
        } else {
            return editableDevice.deviceIdNumber === '';
        }
    }, [editableDevice.deviceIdNumber, context.currentBrand?.deviceNumberPrefix])
    
    const deviceTagSaveDisabled = React.useMemo(() => {
        return editableDevice.deviceTag === '';
    }, [editableDevice.deviceTag])
    
    const deviceTypeOnSave = React.useCallback(() => {
        let newFormDevice = {
            ...formDevice,
            deviceType: editableDevice.deviceType
        }
        
        let showDialog = false;
        if (context.currentLocation?.serialIsDevice) {
            if (formDevice.deviceType && newFormDevice.deviceType?.serialIsDevice) {
                setShowSerialIsDeviceDialog(true);
                showDialog = true;
            }
        }
        if (!showDialog) {
            setFormDevice(newFormDevice)
            setDeviceTypeLocked(!deviceTypeLocked);
        }
    },[context.currentLocation, formDevice, deviceTypeLocked, setFormDevice, editableDevice.deviceType])
    
    const computedTagIdNumber = React.useMemo(() => {
        if (context.currentBrand?.deviceNumberPrefix) {
            if (formDevice.deviceTag?.startsWith(context.currentBrand.deviceNumberPrefix)) {
                return formDevice.deviceTag;
            } else {
                return context.currentBrand?.deviceNumberPrefix + formDevice.deviceTag;
            }
        }
        return formDevice.deviceTag;
    }, [context.currentBrand?.deviceNumberPrefix, formDevice.deviceTag])
    
    const serialIsDeviceDialogSubmit = React.useCallback(() => {
        let newFormDevice = {
            ...formDevice,
            deviceIdNumber: computedTagIdNumber,
            deviceTag: computedTagIdNumber,
            deviceType: editableDevice.deviceType,
        }
        setFormDevice(newFormDevice)
        setDeviceTypeLocked(!deviceTypeLocked);
        setShowSerialIsDeviceDialog(false)
    }, [formDevice, setFormDevice, deviceTypeLocked, computedTagIdNumber, editableDevice.deviceType])
    
    const serialIsDeviceDialogCancel = React.useCallback(() => {
        setShowSerialIsDeviceDialog(false);
    }, [setShowSerialIsDeviceDialog])
    
    const isDeviceIdValid = React.useMemo(() => {
        return validateDeviceIdNumber(computedTagIdNumber).length > 0;
    }, [computedTagIdNumber, validateDeviceIdNumber])
    
    const dialogTitle = React.useMemo(() => {
        if (!isDeviceIdValid) {
            return (
                <div className={'dialog-alert-title'}>
                        {'Changing device type will update the Device ID to '}
                        <span style={{color: 'var(--primary-color)'}}>{computedTagIdNumber}</span>
                </div>
            )
        } else {
            return 'Device type could not be changed';
        }
    }, [isDeviceIdValid, computedTagIdNumber])
    
    const dialogMessages = React.useMemo(() => {
        if (!isDeviceIdValid) {
            return [getLocalizedString('createDevice.serialIsDevice.explain', 'This is because ' + formDevice.deviceType?.deviceTypeName + ' uses its Serial Number as Device ID', [`${formDevice.deviceType?.deviceTypeName}`])];
        } else {
            return [getLocalizedString('createDevice.serialIsDevice.unableToUpdate', formDevice.deviceType?.deviceTypeName + ' uses its Serial Number as the Device ID, but this device\'s Serial Number does not meet the Device ID requirements. Please contact ARC support for help', [`${formDevice.deviceType?.deviceTypeName}`])]
        }
    }, [isDeviceIdValid, formDevice.deviceType?.deviceTypeName])
    
    return (
        <>
            <DeviceDialog
                showDialog={showSerialIsDeviceDialog}
                dialogSubmit={serialIsDeviceDialogSubmit}
                dialogCancel={serialIsDeviceDialogCancel}
                title={dialogTitle}
                messages={dialogMessages}
                isError={isDeviceIdValid}
            >
            </DeviceDialog>
            <div className={'createdevice-confirm-title'}>
                {getLocalizedString('createDevice.conflict.confirm', 'Confirm Device Details')}
            </div>
            <div className={'editdevice-device-id-div'}>
                <div className={'editdevice-device-id-column'} style={{
                    backgroundColor: deviceIdNumberLocked ? 'transparent' : 'rgba(191, 191, 191, 0.2)',
                    paddingLeft: deviceIdNumberLocked ? '0' : '10px',
                    paddingBottom: deviceIdNumberLocked ? '0' : '20px',
                }}>
                    <div className={'editdevice-device-id-row'}>
                        <ErrorWrapper {...createErrorProps(deviceIdNumberLocked ? `${DeviceValidationKeys.deviceIdNumber}` : '')}>
                            <div className={'editdevice-input-div'}>
                                <ARCIdentifierInput
                                    id={'add-device-device-id-input'}
                                    size={'md'}
                                    required={deviceIdNumberLocked}
                                    disabled={true} 
                                    value={formDevice.deviceIdNumber ?? ''}
                                    prefix={context.currentBrand?.deviceNumberPrefix}
                                    label={getLocalizedString('createDevice.deviceIdNumber', 'Device ID')}
                                    tooltip={deviceIdTooltip}
                                    onChange={(event) => {
                                    }}
                                />
                            </div>
                        </ErrorWrapper>
                        {deviceIdNumberLocked &&
                            <EditDeviceButton
                                id="editdeviceid"
                                disabled={!showDeviceIdEdit}
                                style={{marginLeft: '20px'}}
                                onClick={() => {
                                    setDeviceIdNumberLocked(false)
                                    setEditableDevice({
                                        ...formDevice,
                                        deviceIdNumber: ''
                                    });
                                }}
                            >
                                {getLocalizedString('edit', 'Edit')}
                            </EditDeviceButton>
                        }
                    </div>
                    {
                        <div style={{marginTop: '16px', display: deviceIdNumberLocked ? 'none' : 'block'}}>
                            <div className={'editdevice-device-id-row'}>
                                <ErrorWrapper {...createErrorProps(`${DeviceValidationKeys.deviceIdNumber}`)}>
                                    <div className={'editdevice-input-div'}>
                                        <ARCIdentifierInput
                                            id={'add-device-device-id-input'}
                                            size={'md'}
                                            required={false} 
                                            value={editableDevice.deviceIdNumber}
                                            label={getLocalizedString('createDevice.newDeviceIdNumber', 'New Device ID')}
                                            prefix={context.currentBrand?.deviceNumberPrefix}
                                            disabled={deviceIdNumberLocked}
                                            onChange={(value) => {
                                                const error = lockDeviceIdNumber(value);
                                                if (error) {
                                                    const showCharacter = !isAlphanumeric(value) && (fieldErrors[`${DeviceValidationKeys.deviceIdNumber}`]?.error?.length ?? 0) === 0;
                                                    if (showCharacter) {
                                                        setEditableDevice({
                                                            ...formDevice,
                                                            deviceIdNumber: value.toUpperCase(),
                                                            deviceTag: serialIsDeviceId ? value.toUpperCase() : editableDevice.deviceTag
                                                        })
                                                    }
                                                    setFieldError(DeviceValidationKeys.deviceIdNumber, error)
                                                } else {
                                                    setEditableDevice({
                                                        ...formDevice,
                                                        deviceIdNumber: value.toUpperCase(),
                                                        deviceTag: serialIsDeviceId ? value.toUpperCase() : editableDevice.deviceTag
                                                    })
                                                    clearFieldError(DeviceValidationKeys.deviceIdNumber)
                                                }
                                            }}
                                        />
                                        
                                    </div>
                                </ErrorWrapper>
                                <span className={'editdevice-edit-element'}
                                      onClick={
                                          () => {
                                              setDeviceIdNumberLocked(!deviceIdNumberLocked);
                                              setEditableDevice({
                                                  ...formDevice
                                              });
                                              clearFieldError(DeviceValidationKeys.deviceIdNumber)
                                          }
                                      }>
                                    {getLocalizedString('cancel', 'Cancel')}
                                </span>
                            </div>

                            <div className={'editdevice-device-id-row'} style={{
                                marginTop: '40px'
                            }}>
                                <SaveDeviceButton
                                    id="editdeviceid"
                                    variant='contained'
                                    onClick={() => {
                                            setFormDevice({
                                                ...formDevice,
                                                deviceIdNumber: editableDevice.deviceIdNumber,
                                                deviceTag: serialIsDeviceId ? editableDevice.deviceIdNumber : formDevice.deviceTag
                                            })
                                            setDeviceIdNumberLocked(!deviceIdNumberLocked);
                                        }
                                    }
                                    disabled={deviceIdSaveDisabled}
                                >
                                    {getLocalizedString('createDevice.deviceIdNumber.save', 'Save Device ID')}
                                </SaveDeviceButton>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className={'createdevice-other-div'}>
                <div className={'editdevice-device-tag-row u-align-items--end'}>
                    <ErrorWrapper {...createErrorProps(`${DeviceValidationKeys.deviceTag}`)}>
                        <div className={'editdevice-input-div'}>
                            <ARCIdentifierInput
                                id={'add-device-device-tag-input'}
                                size={'md'}
                                required={true} 
                                value={editableDevice.deviceTag}
                                label={getLocalizedString('createDevice.deviceTag', 'Device Serial Number')}
                                tooltip={getLocalizedString('createDevice.deviceTag.tooltip', 'This is the unique identifier associated with the device.')}
                                disabled={deviceTagLocked || serialIsDeviceId}
                                onChange={(event) => {
                                    const error = validateDeviceTag(event);
                                    if (error) {
                                        const showCharacter = !isAlphanumeric(event) && (fieldErrors[`${DeviceValidationKeys.deviceTag}`]?.error?.length ?? 0) === 0;
                                        if (showCharacter) {
                                            setEditableDevice({
                                                ...formDevice,
                                                deviceTag: event
                                            })    
                                        }
                                        setFieldError(DeviceValidationKeys.deviceTag, error);
                                    } else {
                                        setEditableDevice({
                                            ...formDevice,
                                            deviceTag: event
                                        })
                                        clearFieldError(DeviceValidationKeys.deviceTag);
                                    }
                                }}
                            />
                        </div>
                    </ErrorWrapper>
                    {!serialIsDeviceId &&
                        <button
                            className={'editdevice-edit-element'}
                            type={'button'}
                            style={{marginLeft: '20px'}}
                            onClick={
                                () => {
                                    setDeviceTagLocked(!deviceTagLocked);
                                    !deviceTagLocked &&
                                    setEditableDevice({
                                        ...formDevice,
                                    })
                                }
                            }
                            disabled={!showDeviceTagEdit}>
                            {deviceTagLocked ? getLocalizedString('edit', 'Edit') : getLocalizedString('cancel','Cancel')}
                        </button>
                    }
                </div>
                {!deviceTagLocked &&
                    <div className={'editdevice-device-id-row'} style={{
                        marginBottom: '10px', marginTop: '10px'
                    }}>
                        <SaveDeviceButton
                            id="createDeviceId"
                            variant='contained'
                            onClick={
                                () => {
                                    setFormDevice({
                                        ...formDevice,
                                        deviceTag: editableDevice.deviceTag
                                    })
                                    setDeviceTagLocked(!deviceTagLocked);
                                }
                            }
                            disabled={deviceTagSaveDisabled}
                        >
                            {getLocalizedString('createDevice.deviceTag.save', 'Save Serial Number')}
                        </SaveDeviceButton>
                    </div>
                }
                {(deviceTypeContext.deviceTypes && deviceTypeContext.deviceTypes.length > 0) &&
                    <>
                        <div className={'createdevice-device-type-title-div'}>
                            <div className={'createdevice-device-type-title'}>
                                {getLocalizedString('createDevice.deviceType', 'Device Type')}
                                <span className={'createdevice-red-star'}>
                                    {'*'}
                                </span>
                            </div>
                        </div>
                        <div className={'editdevice-device-tag-row'}>
                            <div className={'editdevice-input-div'}>
                                <ARCSelectInput
                                    id={'create-device-select-device-type-input'}
                                    size={'md'}
                                    className={'createdevice-device-type-select'}
                                    value={editableDevice.deviceType?.deviceTypeId}
                                    options={DeviceTypeOptions}
                                    disabled={deviceTypeLocked}
                                    onChange={(option) => {
                                        setEditableDevice({
                                            ...formDevice,
                                            deviceType: option.value !== '' ? deviceTypeContext.deviceTypes.find(dType => dType.deviceTypeId === option.value) : undefined
                                        });
                                    }}
                                />
                            </div>
                            <button
                                className={'editdevice-edit-element'}
                                type={'button'}
                                style={{marginLeft: '20px', marginBottom: '2px'}}
                                onClick={
                                    () => {
                                        setDeviceTypeLocked(!deviceTypeLocked);
                                        !deviceTypeLocked &&
                                            setEditableDevice({
                                                ...formDevice,
                                            })
                                    }
                                }
                                disabled={!showDeviceTypeEdit}>
                                {deviceTypeLocked ? getLocalizedString('edit', 'Edit') : getLocalizedString('cancel', 'Cancel')}
                            </button>
                            
                        </div>
                        {!deviceTypeLocked &&
                            <div className={'editdevice-device-id-row'} style={{
                                marginBottom: '10px'
                            }}>
                                <SaveDeviceButton
                                    id="createDeviceType"
                                    variant='contained'
                                    onClick={
                                        () => {
                                            deviceTypeOnSave()
                                        }
                                    }
                                >
                                    {getLocalizedString('createDevice.deviceType.save', 'Save Device Type')}
                                </SaveDeviceButton>
                            </div>
                        }
                    </>
                }
            </div>
            <div className={'createdevice-save-button-div'}>
                <ARCButton
                    id="adddevice-save"
                    variant={'primary'}
                    size={'md'}
                    fill={'filled'}
                    submit={true}
                    onClick={() => {}}
                    label={getLocalizedString('createDevice.submit', 'Save')}
                >
                </ARCButton>
            </div>
        </>
    )
}

const SaveDeviceButton = styled(Button)({
    maxHeight: '40px',
    borderRadius: '10px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal normal 0 "Proxima Nova"',
    fontSize: calculateResponsiveSizeWithMinMax('2', '19', '8'),
    color: '#FFFFFF',
    background: '#517D9E',
    letterSpacing: '0',
    padding: '10px 20px 10px 20px !important',
    minWidth: '0px !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#444444',
      opacity: '.5'
    }
});

const EditDeviceButton = styled(Button)({
    maxHeight: '40px',
    borderRadius: '10px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal normal 0 "Proxima Nova"',
    fontSize: calculateResponsiveSizeWithMinMax('2', '19', '8'),
    color: '#FFFFFF',
    background: '#517D9E',
    letterSpacing: '0',
    padding: '3px 20px 3px 20px !important',
    minWidth: '0px !important',
    marginLeft: '20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#444444',
      opacity: '.5'
    }
});
