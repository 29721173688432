import * as React from 'react';
import {AppWithFooter} from "../../App";
import AppBar from "../appbar/AppBar";
import {SideBar} from "../sidebar/SideBar";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {appContextSelector} from "../app-context/appContextSlice";
import {selectLocationThunk} from "../app-context/thunks/SelectLocationThunk";
import {useNavigate, useParams} from "react-router-dom";
import {calculatedFontSize, calculateResponsiveSizeWithMinMax} from "../../util/TextUtils";
import {AccordionDetails, Link, Tooltip} from '@material-ui/core';
import {Mail, PhoneInTalk, Error} from "@material-ui/icons";
import {
    CLIENT_PORTAL_ROLE_FAQ,
    DEVICE_FAQ_MODULE,
    DEVICE_LIMIT_FAQ_MODULE,
    EMPLOYEE_FAQ_MODULE,
    KIOSK_ROLE_FAQ
} from "./FaqSections";
import {ServerMessage} from "../ui-components/ServerMessage";
import {hasOnboardingMedia, isDevNumCisProvided, isEmpNumCisProvided} from "../../dtos/Brand";
import Dialog from "@material-ui/core/Dialog";
import Carousel from "../ui-components/Carousel";
import {media_url} from "../../dtos/API_DOMAINS";
import { HELP_EMAIL, HELP_PHONE } from '../../constants/StringConstants';
import {useAppViewContext} from "../app-context/AppViewContainer";
import {getLocalizedString} from "../../util/Localization";
import {ARCButton} from "../ui-components/design-system/ARCButton";
import {ARCAccordion} from "../ui-components/design-system/ARCAccordion";
import "./Faq.scss"

const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const {locationId} = useParams();

    React.useEffect(() => {
        if(locationId && context.currentLocation?.locationId !== parseInt(locationId)) {
            dispatch(selectLocationThunk(parseInt(locationId)));
        }

    }, [context.currentLocation?.locationId, dispatch, locationId]);
}

export const Faq = () => {
    useAutoSelectLocationByUrl();

    const context = useAppSelector(appContextSelector);
    const appViewContext = useAppViewContext();
    const navigate = useNavigate();
    const {qrRequestId, openKioskRole, openClientPortalRole} = useParams();
    const [showDialog, setShowDialog] = React.useState<boolean>(false);

    const userHasOnlyOneLocation = React.useMemo(() => {
        const locationsForBrand = context.authorizationDto?.authorizedLocations.filter(l => l.brand.brandId === context.currentBrand?.brandId);
        return locationsForBrand?.length === 1
    }, [context.authorizationDto?.authorizedLocations, context.currentBrand?.brandId])

    const showEmployeeModule = React.useMemo(() => {
        return !context.currentBrand?.employeeAPIEnabled;
    }, [context.currentBrand?.employeeAPIEnabled]);

    const Modules = React.useMemo(() => {
        let modules = [];

        if(showEmployeeModule) {
            modules.push(EMPLOYEE_FAQ_MODULE)
        }

        modules.push(DEVICE_FAQ_MODULE);

        if(context.currentBrand?.activeDeviceLimit && context.authorizationDto?.authorizedLocations.find(loc => loc.brand.brandId === context.currentBrand?.brandId && loc.activeDeviceLimit)) {
            modules.push(DEVICE_LIMIT_FAQ_MODULE);
        }


        return modules;
    }, [context.authorizationDto?.authorizedLocations, context.currentBrand?.activeDeviceLimit, context.currentBrand?.brandId, showEmployeeModule]);

    const toolTooltip = React.useMemo(() => {
        if (!appViewContext.brandView) {
            return (
                <>
                    <span>{getLocalizedString('faq.toolPin.diffLocation', 'To view the Tools PIN for a different location:')}</span>
                    <br/>
                    <span>{getLocalizedString('faq.toolPin.location1', '1. Click Users in the navigation menu above.')}</span>
                    <br/>
                    <span>{getLocalizedString('faq.toolPin.location2', '2. Under the Location Name filter, select the desired location.')}</span>
                    <br/>
                    <span>{getLocalizedString('faq.toolPin.location3', '3. Click Help in the navigation menu above.')}</span>
                </>
            )
        } else {
            return (
                <>
                    <span>{getLocalizedString('faq.toolPin.selectLocation', 'To view the Tools PIN for a location:')}</span>
                    <br/>
                    <span>{getLocalizedString('faq.toolPin.location1', '1. Click Users in the navigation menu above.')}</span>
                    <br/>
                    <span>{getLocalizedString('faq.toolPin.location2', '2. Under the Location Name filter, select the desired location.')}</span>
                    <br/>
                    <span>{getLocalizedString('faq.toolPin.location3', '3. Click Help in the navigation menu above.')}</span>
                </>
            )
        }
    },[appViewContext.brandView])

    const toolTipLocationName = React.useMemo(() => {
        if(context.demoMode) {
            return getLocalizedString('viewDevices.filter.location', 'Location') + ' ' + context.currentLocation?.locationId
        } else {
            return context.currentLocation?.locationName ?? 'current location';
        }
    }, [context.currentLocation?.locationId, context.currentLocation?.locationName, context.demoMode]);

    const toolTitle = React.useMemo(() => {
        if (appViewContext.brandView || userHasOnlyOneLocation) {
            return getLocalizedString('faq.toolPin.label', 'Kiosk Tools PIN: ')
        } else {
            return getLocalizedString('faq.toolPin.locationLabel', 'Kiosk Tools PIN at ' + toolTipLocationName + ':', [toolTipLocationName])
        }
    },[appViewContext.brandView, toolTipLocationName, userHasOnlyOneLocation])

    const toolPin = React.useMemo(() => {
        if (appViewContext.brandView) {
            return getLocalizedString('faq.toolPin.location', 'Select Location')
        } else {
            return context.currentLocation?.managerPin
        }

    },[appViewContext, context.currentLocation?.managerPin])
    
    const autoOpenSection = React.useMemo(() => {
        if (openClientPortalRole) {
            return CLIENT_PORTAL_ROLE_FAQ
        } else if (openKioskRole) {
            return KIOSK_ROLE_FAQ
        }
        return undefined;
    }, [openClientPortalRole, openKioskRole]);

    return (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    {qrRequestId &&
                    <ServerMessage variant={'success'}>
                        <span style={{display: 'flex', width: '100%', flexWrap: 'wrap', alignItems: 'center'}}>
                            <Link style={{marginRight: '5px', fontSize: calculatedFontSize('.8em', '1.2em'), cursor: 'pointer'}} onClick={() => navigate(`/qr/view/locationId=${context.currentLocation?.locationId}/qrRequestId=${qrRequestId}`)}>
                                {getLocalizedString('faq.labelRequest', 'QR Code Request')}
                            </Link>
                            <span style={{fontSize: calculatedFontSize('.8em', '1.2em')}}>{getLocalizedString('faq.labelRequest.saved', 'submitted.')}</span>
                        </span>
                    </ServerMessage>
                    }
                    
                    <div className={'o-heading--xl'} style={{paddingBottom: '15px', width: '100%'}}>
                        {getLocalizedString('faq.help', 'Help')}
                    </div>

                    <div className={'faq__header'}>
                        <div style={{display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap', gap: '16px 0px'}}>
                            {
                                (context.currentBrand && (isEmpNumCisProvided(context.currentBrand) || isDevNumCisProvided(context.currentBrand))) &&
                                <>
                                    <ARCButton
                                        id={'faq-create-label-request-button'}
                                        fill={'filled'}
                                        variant={'primary'}
                                        size={'md'}
                                        onClick={() => navigate(`/qr/create/locationId=${context.currentLocation?.locationId}`)}
                                        icon={'qr-code'}
                                        label={getLocalizedString('faq.labelRequest.create', 'Request QR Codes')}
                                    >
                                    </ARCButton>
                                </>
                            }
    
                            {(context.currentBrand && hasOnboardingMedia(context.currentBrand)) &&
                            <>
                                <ARCButton
                                    id={'faq-onboarding-guide-button'}
                                    fill={'filled'}
                                    variant={'primary'}
                                    size={'md'}
                                    onClick={() => setShowDialog(true)}
                                    icon={'book'}
                                    label={getLocalizedString('faq.onboarding.view', 'Onboarding Guide')}
                                >
                                </ARCButton>
                                <Dialog maxWidth={'lg'} open={showDialog} onClose={() => {}}>
                                    <Carousel
                                        onClose={() => {
                                            setShowDialog(false);
                                        }}
                                        firstTimeLogin={false}
                                        title={getLocalizedString('faq.onboarding.title', 'Welcome to ARC\'s Client Portal')}
                                        images={[
                                            context.currentBrand.onboardingImageFile1 ? (media_url + context.currentBrand.brandId + '/' + context.currentBrand.onboardingImageFile1) : '',
                                            context.currentBrand.onboardingImageFile2 ? (media_url + context.currentBrand.brandId + '/' + context.currentBrand.onboardingImageFile2) : '',
                                            context.currentBrand.onboardingImageFile3 ? (media_url + context.currentBrand.brandId + '/' + context.currentBrand.onboardingImageFile3) : '',
                                            context.currentBrand.onboardingImageFile4 ? (media_url + context.currentBrand.brandId + '/' + context.currentBrand.onboardingImageFile4) : '',
                                            context.currentBrand.onboardingImageFile5 ? (media_url + context.currentBrand.brandId + '/' + context.currentBrand.onboardingImageFile5) : ''
                                        ].filter(img => img !== '')}>
    
                                    </Carousel>
                                </Dialog>
                            </>
                            }
                            <ARCButton
                                id={'faq-emergency-kiosk-button'}
                                fill={'filled'}
                                variant={'primary'}
                                size={'md'}
                                onClick={() => navigate(`/faq/emergency/keypads/view`)}
                                icon={'warning'}
                                label={getLocalizedString('emergencyPortal.titleFull', 'Emergency Kiosk Access')}
                            >
                            </ARCButton>
                        </div>
                        <div className={'faq__help'}>
                            <div className={'faq__help__contact-info'}>
                                <span className={'o-body-text u-display--flex'}>
                                    <Mail style={{marginRight: '5px'}}/>
                                    {getLocalizedString('support.email', HELP_EMAIL)}
                                </span>
                                <span className={'o-body-text u-display--flex'}>
                                    <PhoneInTalk style={{marginRight: '5px'}}/>
                                    {getLocalizedString('support.phone', HELP_PHONE)}
                                </span>
                            </div>
                            <div className={'faq__help__tool-location'}>
                                {toolTitle}
                                <span style={{color: 'black', fontWeight: 'bold', paddingLeft: '5px', fontSize: 'var(--font-size-16)'}}>
                                    {toolPin}
                                </span>
                                {!userHasOnlyOneLocation &&
                                <Tooltip title={toolTooltip}>
                                    <Error style={{
                                        fontSize: calculateResponsiveSizeWithMinMax('2', '20', '10'),
                                        paddingLeft: '5px'
                                    }}/>
                                </Tooltip>
                                }
                            </div>
                        </div>


                    </div>

                    <div className={'o-heading--m'}>{getLocalizedString('faq.title', 'Frequently Asked Questions')}</div>


                    <div style={{width: '100%', marginTop: '2vh', fontSize: calculateResponsiveSizeWithMinMax('2.5', '24', '18')}}>

                        {Modules.map((module, idx) => {
                            return (
                                <ARCAccordion
                                    key={idx}
                                    title={module.moduleTitle}
                                    sections={
                                        module.moduleSections.map((section, idx) => {
                                            return {
                                                title: section.sectionTitle,
                                                id: section.id,
                                                content: (
                                                    <>
                                                        <AccordionDetails>
                                                            <div>
                                                                {section.sectionContent.sectionDescription}
        
                                                                <div>
                                                                    {section.sectionContent.sectionSteps?.map((step, idx) => {
                                                                        return (
                                                                            <div key={idx}>
                                                                                {step}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
        
                                                                {section.tipsSection &&
                                                                <div>
                                                                    <div className={'faq__tips__title'}>{getLocalizedString('faq.tips', 'TIPS')}</div>
        
                                                                    <ul style={{paddingLeft: '8px', marginTop: '0px'}}>
                                                                        {section.tipsSection.map((tip, idx) => {
                                                                            return (
                                                                                <li key={idx}>
                                                                                    {tip}
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </div>}
                                                            </div>
                                                        </AccordionDetails>
                                                    </>
                                                )
                                            }
                                        })
                                    }
                                    autoOpenSection={autoOpenSection} 
                                >
                                </ARCAccordion>
                            )
                        })}
                    </div>
                </div>
            </main>
        </AppWithFooter>
    );
}
