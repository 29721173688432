import {ReportOption, TooltipSection} from "../features/reports/ReportTypes";

export const DEFAULT_TOOLTIP : TooltipSection[] = [
        {
            title: 'reports.tooltip.description',
            description: 'reports.tooltip.default'
        }
    ]

export const DAILY_DASHBOARD : ReportOption = {
    displayName: 'reports.dailyDashboard.title',
    lookerId: 182,
    lookMLDashboardId: 'bohp_brand_restricted::daily_dashboard',
    viewable: true,
    tooltipText: [
        {
            title: 'reports.tooltip.description',
            description: 'reports.dailyDashboard.tooltip1'
        },
        {
            title: 'reports.dailyDashboard.tooltip2Title',
            description: 'reports.dailyDashboard.tooltip2'
        },
        {
            title: 'reports.dailyDashboard.tooltip3Title',
            description: 'reports.dailyDashboard.tooltip3'
        },
        {
            title: 'reports.dailyDashboard.tooltip4Title',
            description: 'reports.dailyDashboard.tooltip4'
        }
    ]
};

export const KPI_DASHBOARD : ReportOption = {
    displayName: 'reports.kpiDashboard.title',
    lookerId: 256,
    lookMLDashboardId: 'bohp_brand_restricted::kpi_dashboard_single_location',
    viewable: true,
    tooltipText:
        [
            {
                title: 'reports.tooltip.description',
                description: 'reports.kpiDashboard.tooltip1'
            },
            {
                title: 'reports.kpiDashboard.tooltip2Title',
                description: 'reports.kpiDashboard.tooltip2'
            },
            {
                title: 'reports.kpiDashboard.tooltip3Title',
                description: 'reports.kpiDashboard.tooltip3'
            },
            {
                title: 'reports.kpiDashboard.tooltip4Title',
                description: 'reports.kpiDashboard.tooltip4'
            }
        ]
};

export const KPI_DASHBOARD_2_6 : ReportOption = {
    displayName: 'reports.kpiDashboard2To6Locations.title',
    lookerId: 264,
    lookMLDashboardId: 'bohp_brand_restricted::kpi_dashboard_2_6_locations',
    viewable: true,
    tooltipText: [
        {
            title: 'reports.tooltip.description',
            description: 'reports.kpiDashboard2To6Locations.tooltip1'
        },
        {
            title: 'reports.kpiDashboard2To6Locations.tooltip2Title',
            description: 'reports.kpiDashboard2To6Locations.tooltip2'
        },
        {
            title: 'reports.kpiDashboard2To6Locations.tooltip3Title',
            description: 'reports.kpiDashboard2To6Locations.tooltip3'
        },
        {
            title: 'reports.kpiDashboard2To6Locations.tooltip4Title',
            description: 'reports.kpiDashboard2To6Locations.tooltip4'
        }
    ]
};

export const KPI_DASHBOARD_7_PLUS : ReportOption = {
    displayName: 'reports.kpiDashboard7OrMoreLocations.title',
    lookerId: 265,
    lookMLDashboardId: 'bohp_brand_restricted::kpi_dashboard_7_locations',
    viewable: true,
    tooltipText: [
        {
            title: 'reports.tooltip.description',
            description: 'reports.kpiDashboard7OrMoreLocations.tooltip1'
        },
        {
            title: 'reports.kpiDashboard7OrMoreLocations.tooltip2Title',
            description: 'reports.kpiDashboard7OrMoreLocations.tooltip2'
        },
        {
            title: 'reports.kpiDashboard7OrMoreLocations.tooltip3Title',
            description: 'reports.kpiDashboard7OrMoreLocations.tooltip3'
        },
        {
            title: 'reports.kpiDashboard7OrMoreLocations.tooltip4Title',
            description: 'reports.kpiDashboard7OrMoreLocations.tooltip4'
        }
    ]
};

export const EMPLOYEE_DASHBOARD : ReportOption = {
    displayName: 'reports.employeeDashboard.title',
    lookerId: 240,
    lookMLDashboardId: 'bohp_brand_restricted::user_dashboard',
    viewable: true,
    tooltipText: [
        {
            title: 'reports.tooltip.description',
            description: 'reports.employeeDashboard.tooltip1'
        }
    ]
};

export const DEVICE_DASHBOARD : ReportOption = {
    displayName: 'reports.deviceDashboard.title',
    lookerId: 234,
    lookMLDashboardId: 'bohp_brand_restricted::device_dashboard',
    viewable: true,
    tooltipText: [
        {
            title: 'reports.tooltip.description',
            description: 'reports.deviceDashboard.tooltip1'
        }
    ]
}

export const REPORT_OPTIONS : ReportOption[] = [
    DAILY_DASHBOARD,
    {
        displayName: 'reports.deviceSummaryDashboard.title',
        lookerId: 231,
        lookMLDashboardId: 'bohp_brand_restricted::device_summary',
        viewable: true,
        tooltipText: [
            {
                title: 'reports.tooltip.description',
                description: 'reports.deviceSummaryDashboard.tooltip1'
            },
            {
                title: 'reports.deviceSummaryDashboard.tooltip2Title',
                description: 'reports.deviceSummaryDashboard.tooltip2'
            },
            {
                title: 'reports.deviceSummaryDashboard.tooltip3Title',
                description: 'reports.deviceSummaryDashboard.tooltip3'
            },
            {
                title: 'reports.deviceSummaryDashboard.tooltip4Title',
                description: 'reports.deviceSummaryDashboard.tooltip4'
            }
        ]
    },
    {
        displayName: 'reports.employeeSummaryDashboard.title',
        lookerId: 238,
        lookMLDashboardId: 'bohp_brand_restricted::user_summary',
        viewable: true,
        tooltipText: [
            {
                title: 'reports.tooltip.description',
                description: 'reports.employeeSummaryDashboard.tooltip1'
            },
            {
                title: 'reports.employeeSummaryDashboard.tooltip2Title',
                description: 'reports.employeeSummaryDashboard.tooltip2'
            }
        ]
    },
    {
        displayName: 'reports.transactionHistoryDashboard.title',
        lookerId: 233,
        lookMLDashboardId: 'bohp_brand_restricted::transaction_history',
        viewable: true,
        tooltipText: [
            {
                title: 'reports.tooltip.description',
                description: 'reports.transactionHistoryDashboard.tooltip1'
            },
            {
                title: 'reports.transactionHistoryDashboard.tooltip2Title',
                description: 'reports.transactionHistoryDashboard.tooltip2'
            }
        ]
    },
];

export const SCORECARD_DASHBOARD : ReportOption = {
    displayName: 'reports.scorecard.title',
    lookerId: 348,
    lookMLDashboardId: 'bohp_brand_restricted::arc_scorecard',
    viewable: true,
    tooltipText: [
        {
            title: 'reports.scorecard.tooltip1Title',
            description: 'reports.scorecard.tooltip1'
        },
        {
            title: 'reports.scorecard.tooltip2Title',
            description: 'reports.scorecard.tooltip2'
        }
    ]
};

// export const KPI_BQ_DASHBOARD : ReportOption = {
//     displayName: 'reports.kpibq.title',
//     lookerId: 425,
//     lookMLDashboardId: 'bohp_big_query::kpi_dashboard_7_locations_bq',
//     viewable: true,
//     tooltipText: [
//         {
//             title: 'reports.tooltip.description',
//             description: 'reports.kpiDashboard7OrMoreLocations.tooltip1'
//         },
//         {
//             title: 'reports.kpiDashboard7OrMoreLocations.tooltip2Title',
//             description: 'reports.kpiDashboard7OrMoreLocations.tooltip2'
//         },
//         {
//             title: 'reports.kpiDashboard7OrMoreLocations.tooltip3Title',
//             description: 'reports.kpiDashboard7OrMoreLocations.tooltip3'
//         },
//         {
//             title: 'reports.kpiDashboard7OrMoreLocations.tooltip4Title',
//             description: 'reports.kpiDashboard7OrMoreLocations.tooltip4'
//         }
//     ]
// };

export const ALL_LOOKER_DASHBOARDS : ReportOption[] = [
    KPI_DASHBOARD,
    KPI_DASHBOARD_2_6,
    KPI_DASHBOARD_7_PLUS,
    EMPLOYEE_DASHBOARD,
    DEVICE_DASHBOARD,
    ...REPORT_OPTIONS,
    SCORECARD_DASHBOARD,
    // KPI_BQ_DASHBOARD
]