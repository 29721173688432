import * as React from 'react';
import {KioskDto} from "../../../dtos/Kiosk";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core";
import {getLocalizedString} from "../../../util/Localization";
import {useAppSelector} from "../../../store/hooks";
import {appContextSelector} from "../../app-context/appContextSlice";

type KioskDataContainerProps = {
    kiosk: KioskDto | undefined,
    className?: string
}

export const KioskDataContainer:React.ComponentType<KioskDataContainerProps> = ({kiosk, className}) => {
    const context = useAppSelector(appContextSelector);
    const theme = useTheme();
    const isSmOrBelow = useMediaQuery(theme.breakpoints.down('xs'));
    const missingKeypad = React.useMemo(() => {
        return !kiosk?.keypadCode || kiosk?.keypadCode === '';
    }, [kiosk?.keypadCode]);

    return (
        <div className={'kiosk-data-container ' + (!kiosk ? 'disabled' : ' ')  + (className ?? '')}>
            <div className={'kiosk-field kiosk-highlighted-field ' + (missingKeypad ? 'disabled' : '')}>
                <div className={'kiosk-label kiosk-highlighted-label'}>{getLocalizedString('emergencyPortal.kiosk.keypadCode', 'Combo Code')}</div>
                <div className={'kiosk-value kiosk-highlighted-value'}>{missingKeypad ? '-' : kiosk?.keypadCode}</div>
            </div>
            <div className={'kiosk-wrap-container'}>
                {!isSmOrBelow ?
                    <>
                        <div className={'kiosk-field'}>
                            <div className={'kiosk-label' + (!kiosk?.serialNumber ? 'disabled' : '')}>{getLocalizedString('emergencyPortal.kiosk.serialNumber', 'Serial Number')}</div>
                            <div className={'kiosk-value'}>{kiosk?.serialNumber ?? ''}</div>
                        </div>
                        <div className={'kiosk-field'}>
                            <div className={'kiosk-label' + (!kiosk?.modelType ? 'disabled' : '')}>{getLocalizedString('emergencyPortal.kiosk.modelType', 'Kiosk Type')}</div>
                            <div className={'kiosk-value'}>{kiosk?.modelType ?? ''}</div>
                        </div>
                        <div className={'kiosk-field'}>
                            <div className={'kiosk-label' + (!kiosk?.site?.siteName ? 'disabled' : '')}>{getLocalizedString('emergencyPortal.kiosk.siteName', 'Site')}</div>
                            <div className={'kiosk-value'}>{kiosk?.site?.siteName ?? ''}</div>
                        </div>
                        <div className={'kiosk-field kiosk-long-field'}>
                            <div className={'kiosk-label' + (!kiosk?.location?.locationName ? 'disabled' : '')}>{getLocalizedString('emergencyPortal.kiosk.location', 'Location')}</div>
                            <div className={'kiosk-value'}>{context.demoMode && kiosk?.location?.locationId ? getLocalizedString('viewDevices.filter.location') + ' ' + kiosk?.location?.locationId : kiosk?.location?.locationName ?? ''}</div>
                        </div>
                    </>
                    :
                    <>
                        <div className={'kiosk-data-row'}>
                            <div className={'kiosk-field'}>
                                <div className={'kiosk-label' + (!kiosk?.serialNumber ? 'disabled' : '')}>{getLocalizedString('emergencyPortal.kiosk.serialNumber', 'Serial Number')}</div>
                                <div className={'kiosk-value'}>{kiosk?.serialNumber ?? ''}</div>
                            </div>
                            <div className={'kiosk-field'}>
                                <div className={'kiosk-label' + (!kiosk?.modelType ? 'disabled' : '')}>{getLocalizedString('emergencyPortal.kiosk.modelType', 'Kiosk Type')}</div>
                                <div className={'kiosk-value'}>{kiosk?.modelType ?? ''}</div>
                            </div>
                            <div className={'kiosk-field'}>
                                <div className={'kiosk-label' + (!kiosk?.site?.siteName ? 'disabled' : '')}>{getLocalizedString('emergencyPortal.kiosk.siteName', 'Site')}</div>
                                <div className={'kiosk-value'}>{kiosk?.site?.siteName ?? ''}</div>
                            </div>
                        </div>
                        <div className={'kiosk-field kiosk-long-field'}>
                            <div className={'kiosk-label' + (!kiosk?.location?.locationName ? 'disabled' : '')}>{getLocalizedString('emergencyPortal.kiosk.location', 'Location')}</div>
                            <div className={'kiosk-value'}>{kiosk?.location?.locationName ?? ''}</div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
