import {DeviceDto, DeviceValidationKeys} from "../../../../dtos/Device";
import {Dispatch, SetStateAction} from "react";
import * as React from "react";
import ErrorWrapper, {ErrorWrapperProps} from "../../../ui-components/ErrorWrapper";
import {useAppSelector} from "../../../../store/hooks";
import {appContextSelector} from "../../../app-context/appContextSlice";
import {deviceTypeSelector} from "../../deviceTypeSlice";
import {isAlphanumeric} from "../../../../util/TextUtils";
import {CreateDeviceChildComponentNames} from "../../pages/CreateDevice";
import {getLocalizedString} from "../../../../util/Localization";
import {ARCButton} from "../../../ui-components/design-system/ARCButton";
import {ARCIdentifierInput} from "../../../ui-components/design-system/identifier-input/ARCIdentifierInput";

type DeviceIdNumberProps = {
    formDevice: DeviceDto
    setFormDevice: Dispatch<SetStateAction<DeviceDto>>,
    goForward: (name: string) => void,
    fieldErrors: {[fieldName: string]: {
        error: string | undefined,
        shown: boolean,
    }},
    createErrorProps: (field: string) => ErrorWrapperProps,
    setFieldError: (field: string, error: string) => void,
    clearFieldError: (field: string) => void,
    lockDeviceIdNumber: (input: string) => string | undefined
}

export const DeviceIdNumber:React.ComponentType<DeviceIdNumberProps> = (props) => {
    const context = useAppSelector(appContextSelector);
    const deviceTypeContext = useAppSelector(deviceTypeSelector);
    const {formDevice, 
            setFormDevice, 
            goForward,
            fieldErrors,
            createErrorProps,
            setFieldError,
            clearFieldError,
            lockDeviceIdNumber} = props;
            
    const noteText = React.useMemo(() => {
        if (context.currentLocation?.serialIsDevice) {
            if (deviceTypeContext.deviceTypes.length > 0) {
                if (formDevice.deviceType) {
                    if (formDevice.deviceType?.serialIsDevice) {
                        return getLocalizedString('createDevice.deviceType.serialIsDevice.enabled','For ' + formDevice.deviceType.deviceTypeName + ', the Device ID is the same as the Serial Number', [`${formDevice.deviceType.deviceTypeName}`]);
                    } else {
                        return getLocalizedString('createDevice.deviceType.serialIsDevice.disabled', 'For ' + formDevice.deviceType.deviceTypeName + ', the Device ID is distinct from the Serial Number', [`${formDevice.deviceType.deviceTypeName}`]);
                    }
                }
            } else {
                return getLocalizedString('createDevice.serialIsDevice', 'The Device ID is the same as the Serial Number');
            }
        }
        return undefined; // shouldn't get here because the DeviceIdNumber component shouldn't render in this case
    }, [formDevice.deviceType, context.currentLocation, deviceTypeContext.deviceTypes])
    
    const serialIsDeviceId = React.useMemo(() => {
        if (context.currentLocation?.serialIsDevice) {
            if (deviceTypeContext.deviceTypes.length > 0 && !formDevice.deviceType?.serialIsDevice) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }, [context.currentLocation, deviceTypeContext.deviceTypes, formDevice.deviceType])
    
    const onNext = React.useCallback(() => {
        if (formDevice.deviceIdNumber && formDevice.deviceIdNumber.length > 0) {
            if (serialIsDeviceId) {
                goForward(CreateDeviceChildComponentNames.createDeviceForm);
            } else {
                goForward(CreateDeviceChildComponentNames.deviceTag);
            } 
        }
    }, [formDevice.deviceIdNumber, goForward, serialIsDeviceId]) 
    
    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                onNext();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onNext]);
    
    return (
        <>
            <div className={'createdevice-device-id-div'} style={{borderWidth: 0}}>
                <ErrorWrapper {...createErrorProps(`${DeviceValidationKeys.deviceIdNumber}`)}>
                    <div className={'editdevice-input-div'}>
                        <ARCIdentifierInput
                            id={'add-device-device-id-input'}
                            size={'md'}
                            required={true} 
                            disabled={false}
                            value={formDevice.deviceIdNumber}
                            prefix={context.currentBrand?.deviceNumberPrefix}
                            label={getLocalizedString('createDevice.deviceIdNumber', 'Device ID')}
                            onChange={(value) => {
                                const error = lockDeviceIdNumber(value);
                                if (error) {
                                    const showCharacter = !isAlphanumeric(value) && (fieldErrors[`${DeviceValidationKeys.deviceIdNumber}`]?.error?.length ?? 0) === 0;
                                    if (showCharacter) {
                                        setFormDevice({
                                            ...formDevice,
                                            deviceIdNumber: value.toUpperCase(),
                                            deviceTag: serialIsDeviceId ? value.toUpperCase() : formDevice.deviceTag
                                        })
                                    }
                                    setFieldError(DeviceValidationKeys.deviceIdNumber, error)
                                } else {
                                    setFormDevice({
                                        ...formDevice,
                                        deviceIdNumber: value.toUpperCase(),
                                        deviceTag: serialIsDeviceId ? value.toUpperCase() : formDevice.deviceTag
                                    })
                                    clearFieldError(DeviceValidationKeys.deviceIdNumber)
                                }
                            }}
                        />
                    </div>
                </ErrorWrapper>
                <div className={'createdevice-device-id-note-div'}>
                    {noteText &&
                        <>
                            <span className={'createdevice-device-id-note-bold'}>
                                {getLocalizedString('createDevice.note', 'Note: ')}
                            </span>
                            <span className={'createdevice-device-id-note'}>
                                {noteText}
                            </span>
                        </>
                    }
                </div>
            </div>
            
            <div className={'createdevice-save-button-div'}>
                <ARCButton
                    id="device-id-next"
                    variant={'primary'}
                    size={'md'}
                    fill={'filled'}
                    onClick={() => {
                        if (serialIsDeviceId) {
                            goForward(CreateDeviceChildComponentNames.createDeviceForm);
                        } else {
                            goForward(CreateDeviceChildComponentNames.deviceTag);
                        } 
                    }}
                    label={getLocalizedString('next', 'Next')}
                    disabled={!formDevice.deviceIdNumber || formDevice.deviceIdNumber.length === 0}
                >
                </ARCButton>
            </div>
        </>
    )
}

