import {Dispatch, SetStateAction} from "react";
import {BrandDto, EmployeeNumberComposition} from "../dtos/Brand";
import {MAX_FIRST_NAME_LENGTH, MAX_LAST_NAME_LENGTH} from "../constants/EmployeeConstants";
import {nameContainsInvalidCharacters, isAlphabeticStrict, isAlphanumeric, isNumericStrict} from "./TextUtils";
import {isMasterBadgeIdPattern} from "../dtos/Employee";
import {
    FR_KIOSK_NONE, FR_KIOSK_EMPLOYEE, FR_KIOSK_MANAGER, FR_KIOSK_TEAM_LEAD,
    KIOSK_EMPLOYEE,
    KIOSK_MANAGER,
    KIOSK_NONE,
    KIOSK_TEAM_LEAD, PORTAL_GROUPS
} from "../features/app-context/containers/UserPermissions";
import {DevicePickupPermissionDto} from "../dtos/DevicePickupPermission";
import {getLocalizedString} from "./Localization";

export type EmployeeValidationDto = {
    validEmployees: string[],
    firstNameErrors: string[],
    middleInitialErrors: string[],
    lastNameErrors: string[],
    badgeIdErrors: string[],
    kioskGroupErrors: string[],
    statusErrors: string[],
    deviceGroupErrors: string[],
    portalAccessErrors: string[],
    emailErrors: string[],
    invalidEmployeesCount: number,
    duplicateEmployees: string[],
}
export function validateEmployeeUpload(file: File, brand: BrandDto, permissions:  DevicePickupPermissionDto[], setProgress: Dispatch<SetStateAction<number>>, templateHeader: string[]): Promise<EmployeeValidationDto> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (event: ProgressEvent<FileReader>) => {
            if (!event.target) {
                reject(new Error('File reader target not found'));
                return;
            }
            
            const csvData: string = event.target.result as string;
            const rows: string[] = csvData.replace(/\r/g, '').split('\n');
            if (rows.length <= 1) {
                reject(new Error('Empty File'))
                return;
            } else {
                const header = rows[0].split(',');
                if (header.length !== templateHeader.length) {
                    reject(new Error('Header does not match template'))
                    return;
                }
                for (let i = 0; i < header.length; i++) {
                    if (header[i] !== templateHeader[i]) {
                        reject(new Error('Header does not match template'))
                        return;
                    }
                }
            }
            const totalRows = rows.length;
            let processedRows = 0;
            let employeeValidationDto: EmployeeValidationDto = {
                validEmployees: [],
                firstNameErrors: [],
                middleInitialErrors: [],
                lastNameErrors: [],
                badgeIdErrors: [],
                kioskGroupErrors: [],
                statusErrors: [],
                deviceGroupErrors: [],
                portalAccessErrors: [],
                emailErrors: [],
                invalidEmployeesCount: 0,
                duplicateEmployees: []
            }
            
            const existingEmployees: {firstName: string; middleName: string; lastName: string;}[] = [];
            
            setProgress(0);
            if (totalRows > 0) {
                const updateStateInterval = Math.ceil(totalRows/100);
                const asyncProcessRows = async () => {
                    const delay = () => new Promise((resolve) => setTimeout(resolve, 0));
                    for (let i = 0; i < totalRows; i++) {
                        const rowIndex = i;
                        const row = rows[i];
                        const isEmptyRow = row.replace(/,| /g, '') === '';
                        if (rowIndex >= 1 && !isEmptyRow) {
                            const columns: string[] = row.split(',');
                            processedRows++;
                            const progress = Math.ceil((processedRows / totalRows) * 100);
                            let validRow = true;
                            const firstName = columns[0];
                            const middleInitial = columns[1];
                            const lastName = columns[2];
                            const kioskGroup = columns[3];
                            const badgeId = columns[4];
                            const status = columns[5];
                            const deviceGroup = columns[6];
                            const portalAccess: any = columns[7];
                            const email = columns[8];
                            let employeeKey = getLocalizedString('import.review.row', 'Row: ') + (rowIndex + 1).toString();
                            if (!(!!firstName && firstName.length > 0)) {
                                employeeValidationDto.firstNameErrors.push(employeeKey);
                                validRow = false;
                            } else if (nameContainsInvalidCharacters(firstName)) {
                                employeeValidationDto.firstNameErrors.push(employeeKey);
                                validRow = false;
                            } else if (firstName.length > MAX_FIRST_NAME_LENGTH) {
                                employeeValidationDto.firstNameErrors.push(employeeKey);
                                validRow = false;
                            }

                            if (!!middleInitial && middleInitial.length > 0) {
                                if (middleInitial.length > 1) {
                                    employeeValidationDto.middleInitialErrors.push(employeeKey);
                                    validRow = false;
                                } else if (!isAlphabeticStrict(middleInitial)) {
                                    employeeValidationDto.middleInitialErrors.push(employeeKey);
                                    validRow = false;
                                }
                            }

                            if (!(!!lastName && lastName.length > 0)) {
                                employeeValidationDto.lastNameErrors.push(employeeKey);
                                validRow = false;
                            } else if (nameContainsInvalidCharacters(lastName)) {
                                employeeValidationDto.lastNameErrors.push(employeeKey);
                                validRow = false;
                            } else if (lastName.length > MAX_LAST_NAME_LENGTH) {
                                employeeValidationDto.lastNameErrors.push(employeeKey);
                                validRow = false;
                            }
                            
                            if (!!status) {
                                if (status !== 'Active' && status !== 'Actif' && status !== 'Inactive' && status !== 'Inactif') {
                                    employeeValidationDto.statusErrors.push(employeeKey);
                                    validRow = false;
                                }
                            }

                            if (!!badgeId && badgeId.length > 0) {
                                if (isMasterBadgeIdPattern(badgeId)) {
                                    employeeValidationDto.badgeIdErrors.push(employeeKey);
                                    validRow = false;
                                } else if (brand.employeeNumberPrefix && !badgeId.startsWith(brand.employeeNumberPrefix)) {
                                    employeeValidationDto.badgeIdErrors.push(employeeKey);
                                    validRow = false;
                                }

                                if (brand.employeeNumberLengthMin) {
                                    if (brand.employeeNumberLengthMax) {
                                        if (badgeId.length < brand.employeeNumberLengthMin || badgeId.length > brand.employeeNumberLengthMax) {
                                            if (brand.employeeNumberLengthMin === brand.employeeNumberLengthMax) {
                                                employeeValidationDto.badgeIdErrors.push(employeeKey);
                                                validRow = false;
                                            } else {
                                                employeeValidationDto.badgeIdErrors.push(employeeKey);
                                                validRow = false;
                                            }
                                        }
                                    } else if (badgeId.length < brand.employeeNumberLengthMin) {
                                        employeeValidationDto.badgeIdErrors.push(employeeKey);
                                        validRow = false;
                                    }
                                }

                                if (brand.employeeNumberComposition) {
                                    if (brand.employeeNumberComposition === EmployeeNumberComposition.NUMERIC && !isNumericStrict(badgeId.substring(brand.employeeNumberPrefix?.length ?? 0))) {
                                        employeeValidationDto.badgeIdErrors.push(employeeKey);
                                        validRow = false;
                                    } else if (brand.employeeNumberComposition === EmployeeNumberComposition.ALPHANUMERIC && !isAlphanumeric(badgeId)) {
                                        employeeValidationDto.badgeIdErrors.push(employeeKey);
                                        validRow = false;
                                    }
                                }
                            } else {
                                if (!!kioskGroup && KIOSK_NONE !== kioskGroup && FR_KIOSK_NONE !== kioskGroup) {
                                    employeeValidationDto.badgeIdErrors.push(employeeKey);
                                    validRow = false;
                                }
                            }


                            if (!!kioskGroup) {
                                if (kioskGroup !== KIOSK_NONE && kioskGroup !== KIOSK_EMPLOYEE && kioskGroup !== KIOSK_MANAGER && kioskGroup !== KIOSK_TEAM_LEAD &&
                                    kioskGroup !== FR_KIOSK_NONE && kioskGroup !== FR_KIOSK_EMPLOYEE && kioskGroup !== FR_KIOSK_MANAGER && kioskGroup !== FR_KIOSK_TEAM_LEAD) {
                                    employeeValidationDto.kioskGroupErrors.push(employeeKey);
                                    validRow = false;
                                }
                            }

                            if (!!deviceGroup) {
                                if (permissions && permissions.length > 0) {
                                    let foundDeviceGroup = false;
                                    permissions.forEach((devicePermission) => {
                                        if (devicePermission.permissionName === deviceGroup) {
                                            foundDeviceGroup = true;
                                        }
                                    })
                                    if (!foundDeviceGroup) {
                                        employeeValidationDto.deviceGroupErrors.push(employeeKey);
                                        validRow = false;
                                    }
                                }
                            }

                            if (!!portalAccess) {
                                if (portalAccess !== "None" && portalAccess !== "Aucun" && !PORTAL_GROUPS.includes(portalAccess)) {
                                    employeeValidationDto.portalAccessErrors.push(employeeKey);
                                    validRow = false;
                                } else if (PORTAL_GROUPS.includes(portalAccess) && !(email && email.length > 0)) {
                                    employeeValidationDto.emailErrors.push(employeeKey);
                                    validRow = false;
                                }
                            }
                            
                            if (existingEmployees.length > 0) {
                                for (let i = 0; i < existingEmployees.length; i++) {
                                    if (existingEmployees[i].firstName === firstName && 
                                        existingEmployees[i].lastName === lastName && 
                                        existingEmployees[i].middleName === middleInitial
                                    ) {
                                        employeeValidationDto.duplicateEmployees.push(employeeKey)
                                        validRow = false;
                                    }
                                }
                            }
                            
                            if (validRow) {
                                existingEmployees.push({firstName: firstName, middleName: middleInitial, lastName: lastName})
                                employeeValidationDto.validEmployees.push(firstName + ' ' + lastName);
                            } else {
                                employeeValidationDto.invalidEmployeesCount++;
                            }

                            if ((processedRows % updateStateInterval) === 0) {
                                setProgress(progress);
                                await delay();
                            }
                        }
                    }
                    setProgress(100);
                }
                await asyncProcessRows()
            }
            resolve(employeeValidationDto);
        };

        reader.onerror = (event: ProgressEvent<FileReader>) => {
            reject(event.target?.error || new Error('Unknown error occurred'));
        };

        reader.readAsText(file);
    });
}
