import * as React from 'react';
import {FormEvent} from 'react';
import AppBar from "../../appbar/AppBar";
import {SideBar} from "../../sidebar/SideBar";
import {IconButton} from "@material-ui/core";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextSelector} from "../../app-context/appContextSlice";
import {
    DEV_STATUS_INACTIVE,
    DEV_STATUS_PENDING,
    DeviceDto,
    DeviceValidationKeys,
    getDeviceIdNumberValidation
} from "../../../dtos/Device";
import {
    createDevice,
    deviceContextActions,
    deviceContextSelector,
    getDeviceImportResult,
    updateDuplicateList
} from "../deviceSlice";
import {AppWithFooter} from "../../../App";
import {LocationState} from "../../../@types/types";
import {
    ErrorMessageSectionItem, FormTitle
} from "../../ui-components/StyledComponents";
import {deviceTypeSelector, getDeviceTypes} from "../deviceTypeSlice";
import {selectLocationThunk} from "../../app-context/thunks/SelectLocationThunk";
import {DeviceError} from "../components/DeviceError";
import {ServerMessage} from "../../ui-components/ServerMessage";
import {ArrowBack} from "@material-ui/icons";
import "../../../css/CreateDevice.css"
import {CreateExistingDevice} from "../components/create-device/CreateExistingDevice";
import {DeviceTypeDto} from "../../../dtos/DeviceType";
import {CreateDeviceForm} from "../components/create-device/CreateDeviceForm";
import {DeviceType} from "../components/create-device/DeviceType";
import {DeviceIdNumber} from "../components/create-device/DeviceIdNumber";
import {DeviceTag} from "../components/create-device/DeviceTag";
import {
    isAlphanumeric
} from "../../../util/TextUtils";
import ErrorWrapper, {useFieldErrors} from "../../ui-components/ErrorWrapper";
import {MAX_DEVICE_TAG_LENGTH} from "../../../constants/DeviceConstants";
import {Option} from "../../ui-components/ColorSelect";
import {getLocalizedString} from "../../../util/Localization";
import {ARCButton} from "../../ui-components/design-system/ARCButton";
import {ARCSelectInput} from "../../ui-components/design-system/ARCSelectInput";
import {ARCIdentifierInput} from "../../ui-components/design-system/identifier-input/ARCIdentifierInput";

const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const deviceContext = useAppSelector(deviceContextSelector);
    const location = useLocation();
    const {locationId} = useParams();
    const searchParams = React.useMemo(() => {return new URLSearchParams(location.search)}, [location.search]);
    const portalUploadStatusId = searchParams ? searchParams.get('portalUploadStatusId') : undefined;
    const currentLocationId = React.useMemo(() => {
        return locationId ?? (location && searchParams ? searchParams.get('locationId') : undefined);
    }, [locationId, location, searchParams])

    React.useEffect(() => {
        if(currentLocationId && context.currentLocation?.locationId !== parseInt(currentLocationId)) {
            dispatch(selectLocationThunk(parseInt(currentLocationId)));
        }
    }, [context.currentLocation?.locationId, dispatch, currentLocationId]);

    React.useEffect(() => {
        if (portalUploadStatusId && !deviceContext.importResult && currentLocationId) {
            dispatch(getDeviceImportResult({
                locationId: parseInt(currentLocationId),
                portalUploadStatusId: parseInt(portalUploadStatusId)
            }));
        }
    }, [portalUploadStatusId, deviceContext.importResult, currentLocationId, dispatch])
}

export const CreateDeviceChildComponentNames = {
    createDeviceForm: 'createDeviceForm',
    deviceType: 'deviceType',
    deviceIdNumber: 'deviceIdNumber',
    deviceTag: 'deviceTag'
}

export const CreateDevice = () => {
    useAutoSelectLocationByUrl();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const deviceContext = useAppSelector(deviceContextSelector);
    const deviceTypeContext = useAppSelector(deviceTypeSelector);
    const [createDeviceSuccessMessage, setCreateDeviceSuccessMessage] = React.useState<string | undefined>(undefined);
    const location = useLocation();
    const searchParams = location ? new URLSearchParams(location.search) : undefined;
    const deviceIdNumber = searchParams ? searchParams.get('deviceIdNumber') : undefined;
    const deviceTag = searchParams ? searchParams.get('deviceTag') : undefined;
    const deviceType = searchParams ? searchParams.get('deviceType') : undefined;
    const uploadIndex = searchParams ? searchParams.get('uploadIndex') : undefined;

    const [formDevice, setFormDevice] = React.useState<DeviceDto>({
        deviceId: undefined,
        deviceTag: '',
        deviceIdNumber: '',
        status: DEV_STATUS_PENDING,
        locationId: undefined,
        inactiveReason: '',
        inactiveReasonDetail: '',
        deviceType: deviceTypeContext.deviceTypes.length === 1 ? deviceTypeContext.deviceTypes[0] : undefined
    });

    const DeviceTypeOptions = React.useMemo(() => {
        const options : Option[] = deviceTypeContext.deviceTypes.map(deviceType => {
            return {
                value: deviceType.deviceTypeId,
                label: deviceType.deviceTypeName
            }
        });

        return [{value: undefined, label: getLocalizedString('createDevice.selectDeviceType', 'Select Device Type')}, ...options];
    }, [deviceTypeContext.deviceTypes]);

    React.useEffect(() => {
        if (deviceTag != null) {
            let deviceTypeDto: DeviceTypeDto | undefined;
            deviceTypeContext.deviceTypes.forEach((type) => {
                if (type.deviceTypeName === deviceType) {
                    deviceTypeDto = type;
                }
            })
            setFormDevice((device) => {
                return {
                    ...device,
                    deviceTag: deviceTag,
                    deviceIdNumber: deviceIdNumber ?? '',
                    deviceType: deviceTypeDto ?? (deviceTypeContext.deviceTypes.length === 1 ? deviceTypeContext.deviceTypes[0] : undefined)
                };
            })
        }
    }, [deviceTag, deviceIdNumber, deviceType, deviceTypeContext.deviceTypes])
    
    const duplicateListUpdated = React.useMemo(() => {
        if (deviceContext.importResult) {
            let duplicateList = deviceContext.importResult?.duplicateList.split(',');
            if (uploadIndex && duplicateList) {
                const idx = parseInt(uploadIndex);
                duplicateList[idx] += (' ' + getLocalizedString('createDevice.transferred', 'TRANSFERRED'))
                const duplicateString = duplicateList.join(',')
                return duplicateString;
            }
        }
        return undefined;
    }, [deviceContext.importResult, uploadIndex])

    const {fieldErrors,
        setFieldErrors,
        createErrorProps,
        setFieldError,
        clearFieldError,
        clearAllErrors} = useFieldErrors();

    React.useEffect(() => {
        return () => {dispatch(deviceContextActions.resetSaveDevice())}
    }, [dispatch]);

    React.useEffect(() => {
        if(context.currentLocation?.locationId) {
            dispatch(getDeviceTypes({locationId: context.currentLocation.locationId, brandId: context.currentBrand?.brandId}));
        }
    }, [context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch]);

    const {state} = useLocation() as LocationState;
    React.useEffect(() => {
        if(state) {
            const { editDevice } = state; // Read values passed on state
            let defaultDeviceType: number | undefined = undefined;
            if(deviceTypeContext.deviceTypes.length === 1) {
                if(formDevice.deviceType?.deviceTypeId !== deviceTypeContext.deviceTypes[0].deviceTypeId) {
                    defaultDeviceType = deviceTypeContext.deviceTypes[0].deviceTypeId;
                }
            }
            if(editDevice) {
                setFormDevice( prevState => ({
                    ...prevState,
                    deviceType: editDevice.deviceType ? editDevice.deviceType : defaultDeviceType ? deviceTypeContext.deviceTypes[0] : undefined
                }))
            }
        }
    }, [deviceTypeContext.deviceTypes, formDevice.deviceType?.deviceTypeId, state]);

    const computedDeviceIdNumber = React.useMemo(() => {
        if(context.currentBrand?.deviceNumberPrefix) {
            if(formDevice.deviceIdNumber.startsWith(context.currentBrand.deviceNumberPrefix)) {
                return formDevice.deviceIdNumber;
            } else {
                return context.currentBrand.deviceNumberPrefix + formDevice.deviceIdNumber;
            }
        } else {
            return formDevice.deviceIdNumber;
        }
    }, [context.currentBrand?.deviceNumberPrefix, formDevice.deviceIdNumber]);
    
    const serialIsDeviceId = React.useMemo(() => {
        if (context.currentLocation?.serialIsDevice) {
            if (deviceTypeContext.deviceTypes.length > 0 && !formDevice.deviceType?.serialIsDevice) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }, [context.currentLocation?.serialIsDevice, deviceTypeContext.deviceTypes, formDevice.deviceType?.serialIsDevice])
    
    const brandProvidedValidationOnSave = (input: string, minLength: number, maxLength: number) => {
        if (minLength) {
            if (maxLength && (input?.length < minLength || input?.length > maxLength)) {
                if (minLength === maxLength) {
                    return getLocalizedString('createDevice.deviceIdNumber.min', `Device ID must be ${minLength} characters`, [`${minLength}`]);
                } else {
                    return getLocalizedString('createDevice.deviceIdNumber.minAndMax', `Device ID must be between ${minLength} and ${maxLength} characters`, [`${minLength}`, `${maxLength}`]);
                }
            } else if (input?.length < minLength) {
                return getLocalizedString('createDevice.deviceIdNumber.atLeastMin', `Device ID must be at least ${minLength} characters`, [`${minLength}`]);
            }
        }
        return undefined;
    }
    const cisProvidedValidationOnSave = (input: string, length: number | null) => {
        if (length !== null && input?.length !== length) {
            return getLocalizedString('createDevice.deviceIdNumber.min', `Device ID must be ${length} characters`, [`${length}`]);
        }
        return undefined;
    }

    const validateDeviceIdNumber = React.useCallback((input: string) => {
        if (input?.length > 0) {
            if (!isAlphanumeric(input)) {
                return getLocalizedString('createDevice.deviceIdNumber.specialCharacters', 'Device ID Number cannot include special characters');
            }
        }
        if (context.currentBrand) {
            const minLength: number | null = context.currentBrand.deviceNumberLengthMin;
            const maxLength: number | null = context.currentBrand.deviceNumberLengthMax;
            const length: number | null = context.currentBrand.deviceNumberLength;
            const brandProvidedError = brandProvidedValidationOnSave(input, minLength, maxLength);
            const cisProvidedError = cisProvidedValidationOnSave(input, length);
            return getDeviceIdNumberValidation(minLength, length, serialIsDeviceId, brandProvidedError, cisProvidedError);
        }
        return '';
    }, [context.currentBrand, serialIsDeviceId]);
    
    const brandProvidedValidationOnChange = (input: string, minLength: number, maxLength: number) => {
        if (minLength) {
            if (maxLength && (input?.length > maxLength)) {
                if (minLength === maxLength) {
                    return getLocalizedString('createDevice.deviceIdNumber.min', `Device ID must be ${minLength} characters`, [`${minLength}`]);
                } else {
                    return getLocalizedString('createDevice.deviceIdNumber.minAndMax', `Device ID must be between ${minLength} and ${maxLength} characters`, [`${minLength}`, `${maxLength}`]);
                }
            }
        }
        return undefined;
    }
    const cisProvidedValidationOnChange = (input: string, length: number | null) => {
        if (length !== null && input?.length > length) {
            return getLocalizedString('createDevice.deviceIdNumber.min', `Device ID must be ${length} characters`, [`${length}`]);
        }
        return undefined;
    }
    
    const lockDeviceIdNumber = React.useCallback((input: string) => {
        if (input?.length > 0) {
            if (!isAlphanumeric(input)) {
                return getLocalizedString('createDevice.deviceIdNumber.specialCharacters', 'Device ID Number cannot include special characters');
            }
            if (context.currentBrand) {
                const minLength: number | null = context.currentBrand.deviceNumberLengthMin;
                const maxLength: number | null = context.currentBrand.deviceNumberLengthMax;
                const length: number | null = context.currentBrand.deviceNumberLength;
                const brandProvidedError = brandProvidedValidationOnChange(input, minLength, maxLength);
                const cisProvidedError = cisProvidedValidationOnChange(input, length);
                return getDeviceIdNumberValidation(minLength, length, serialIsDeviceId, brandProvidedError, cisProvidedError);
            }
        }
        return '';
    }, [context.currentBrand, serialIsDeviceId]);

    const validateDeviceTag = React.useCallback((deviceTag: string) => {
        if (deviceTag?.length > 0 && !isAlphanumeric(deviceTag)) {
            return getLocalizedString('createDevice.serialNumber.specialCharacters', 'Serial Number cannot include special characters');    
        }
        if(deviceTag?.length > MAX_DEVICE_TAG_LENGTH) {
            return getLocalizedString('createDevice.serialNumber.max', 'Serial Number cannot exceed 25 characters');
        }

        return '';
    }, []);

    const validateDevice = React.useCallback(() => {
        const deviceNumberError = validateDeviceIdNumber(computedDeviceIdNumber);

        let hasErrors = false;
        let newFieldErrors: typeof fieldErrors = {};

        const deviceTagError = validateDeviceTag(formDevice.deviceTag);
        if(deviceTagError) {
            hasErrors = true;
            newFieldErrors = ({...newFieldErrors, [`${DeviceValidationKeys.deviceTag}`]: {
                error: deviceTagError,
                shown: true
            }});
        }

        if(!!deviceNumberError) {
            hasErrors = true;
            newFieldErrors = ({...newFieldErrors, [`${DeviceValidationKeys.deviceIdNumber}`]: {
                error: deviceNumberError,
                shown: true
            }});
        }

        if(hasErrors) {
            setFieldErrors(newFieldErrors);
        }

        return !hasErrors;
    }, [validateDeviceIdNumber, computedDeviceIdNumber, validateDeviceTag, setFieldErrors, formDevice.deviceTag]);
    
    const deviceIdTooltip = React.useMemo(() => {
        let toolTipMessage = '';
        if (context.currentBrand?.deviceNumberLengthMin) {
            if (context.currentBrand?.deviceNumberLengthMax) {
                if (context.currentBrand?.deviceNumberLengthMin === context.currentBrand?.deviceNumberLengthMax) {
                    toolTipMessage += getLocalizedString('createDevice.deviceIdNumber.min', `Device ID must be ${context.currentBrand?.deviceNumberLengthMin} characters`, [`${context.currentBrand?.deviceNumberLengthMax}`]);
                } else {
                    toolTipMessage += getLocalizedString('createDevice.deviceIdNumber.minAndMax', `Device ID must be between ${context.currentBrand?.deviceNumberLengthMin} and ${context.currentBrand?.deviceNumberLengthMax} characters`, [`${context.currentBrand?.deviceNumberLengthMin}`, `${context.currentBrand?.deviceNumberLengthMax}`]);
                }
            } else {
                toolTipMessage += getLocalizedString('createDevice.deviceIdNumber.atLeastMin', `Device ID must be at least ${context.currentBrand?.deviceNumberLengthMin} characters`, [`${context.currentBrand?.deviceNumberLengthMin}`]);
            }
        }
        const isBrandProvided = (!!context.currentBrand?.deviceNumberLengthMin) && (serialIsDeviceId || !context.currentBrand?.deviceNumberLength); 
        if (!isBrandProvided) {
            if (context.currentBrand?.deviceNumberLength) {
                if (toolTipMessage.length > 0) {
                    toolTipMessage += ' or '
                }
                toolTipMessage += getLocalizedString('createDevice.deviceIdNumber.min', `Device ID must be ${context.currentBrand?.deviceNumberLength} characters`, [`${context.currentBrand?.deviceNumberLength}`]);
            }
        }
        return toolTipMessage;
    }, [serialIsDeviceId,
                context.currentBrand?.deviceNumberLength,
                context.currentBrand?.deviceNumberLengthMax,
                context.currentBrand?.deviceNumberLengthMin])

    const submitForm = React.useCallback((event : FormEvent) => {
        event.preventDefault();

        if(!validateDevice()) {
            return;
        }
        clearAllErrors()
        if(context.currentLocation?.locationId) {
            dispatch(createDevice({deviceDto: {...formDevice, deviceIdNumber: computedDeviceIdNumber}, locationId: context.currentLocation.locationId}));
        }

    }, [computedDeviceIdNumber, context.currentLocation?.locationId, dispatch, formDevice, validateDevice, clearAllErrors]);

    React.useEffect(() => {
        if(deviceContext.saveSuccessful) {
            navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`,
                {
                    state: {
                        editingFromDeviceId: deviceContext.savedDeviceId,
                        editingFromDeviceIdNumber: formDevice.deviceIdNumber,
                        editDeviceFrom: 'create',
                        createDeviceSuccessMessage: createDeviceSuccessMessage
                    }
                });
            if (deviceContext.importResult && uploadIndex !== undefined && duplicateListUpdated) {
                dispatch(updateDuplicateList({
                    portalUploadStatusId: deviceContext.importResult?.portalUploadStatusId,
                    duplicateList: duplicateListUpdated,
                }));
            }
            dispatch(deviceContextActions.resetSaveDevice());
        }
    }, [context.currentBrand?.brandId, 
              context.currentLocation?.locationId, 
              deviceContext.saveSuccessful, 
              deviceContext.savedDeviceId, 
              dispatch, 
              formDevice.deviceIdNumber,
              createDeviceSuccessMessage, 
              navigate,
              deviceContext.importResult, 
              uploadIndex, 
              duplicateListUpdated])
    
    const [currentDeviceChildComponent, setCurrentDeviceChildComponent] = React.useState<string>(CreateDeviceChildComponentNames.createDeviceForm);
    const [previousDeviceChildComponents, setPreviousDeviceChildComponents] = React.useState<string[]>([]);
    
    const goForward = React.useCallback((name: string) => {
        setPreviousDeviceChildComponents((previousDeviceChildComponents) => [...previousDeviceChildComponents, currentDeviceChildComponent]);
        setCurrentDeviceChildComponent(name);
    }, [currentDeviceChildComponent]);
    
    const goBack = React.useCallback(() => {
        const prevState = previousDeviceChildComponents.pop();
        if (prevState) {
            setPreviousDeviceChildComponents([...previousDeviceChildComponents]);
            setCurrentDeviceChildComponent(prevState);
        } else {
            navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`);
        }
    }, [previousDeviceChildComponents, 
                navigate,
                context.currentLocation,
                context.currentBrand]);
    
    const createDeviceChildComponents = React.useMemo(() => {
        switch (currentDeviceChildComponent) {
            case CreateDeviceChildComponentNames.createDeviceForm:
                return (
                    <CreateDeviceForm
                        formDevice={formDevice}
                        setFormDevice={setFormDevice}
                        fieldErrors={fieldErrors}
                        validateDeviceIdNumber={validateDeviceIdNumber}
                        validateDeviceTag={validateDeviceTag}
                        createErrorProps={createErrorProps}
                        setFieldError={setFieldError}
                        clearFieldError={clearFieldError}
                        lockDeviceIdNumber={lockDeviceIdNumber}
                        deviceIdTooltip={deviceIdTooltip}/>
                )
            case CreateDeviceChildComponentNames.deviceType:
                return (
                    <DeviceType
                        formDevice={formDevice}
                        setFormDevice={setFormDevice}
                        goForward={goForward}/>
                )
            case CreateDeviceChildComponentNames.deviceIdNumber:
                return (
                    <DeviceIdNumber
                        formDevice={formDevice}
                        setFormDevice={setFormDevice}
                        goForward={goForward}
                        fieldErrors={fieldErrors}
                        createErrorProps={createErrorProps}
                        setFieldError={setFieldError}
                        clearFieldError={clearFieldError}
                        lockDeviceIdNumber={lockDeviceIdNumber}/>
                )
            case CreateDeviceChildComponentNames.deviceTag:
                return (
                    <DeviceTag
                        formDevice={formDevice}
                        setFormDevice={setFormDevice}
                        goForward={goForward}
                        fieldErrors={fieldErrors}
                        createErrorProps={createErrorProps}
                        setFieldError={setFieldError}
                        clearFieldError={clearFieldError}
                        validate={validateDeviceTag}/>
                )
        }
    }, [currentDeviceChildComponent, 
              formDevice, 
              fieldErrors,
              validateDeviceIdNumber, 
              goForward, 
              validateDeviceTag,
              lockDeviceIdNumber,
              deviceIdTooltip, 
              clearFieldError, 
              createErrorProps, 
              setFieldError]);
    
    React.useEffect(() => {
        if (deviceContext.importResult && uploadIndex) {
            setCurrentDeviceChildComponent(CreateDeviceChildComponentNames.createDeviceForm) // always go to main form from upload 
        } else {
            if (context.currentLocation?.serialIsDevice) {
                if (deviceTypeContext.deviceTypes.length > 0) {
                    setCurrentDeviceChildComponent(CreateDeviceChildComponentNames.deviceType)
                } else {
                    setCurrentDeviceChildComponent(CreateDeviceChildComponentNames.deviceIdNumber)
                }
            } else {
                setCurrentDeviceChildComponent(CreateDeviceChildComponentNames.createDeviceForm)
            }
        }
    }, [context.currentLocation?.serialIsDevice, deviceTypeContext.deviceTypes, setCurrentDeviceChildComponent, deviceContext.importResult, uploadIndex])
        
    return  (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    <div className={'createdevice-server-message-div'}>
                        {deviceContext.getDeviceForEditErrorMessage &&
                            <ServerMessage variant={'error'}>
                                <ErrorMessageSectionItem>
                                    {deviceContext.getDeviceForEditErrorMessage}
                                </ErrorMessageSectionItem>
                            </ServerMessage>
                        }
                        {deviceContext.createErrorMessage &&
                            <div style={{width: '100%'}}>
                                {deviceContext.createErrorMessage.split('\n').map((error, key) => {
                                    return (
                                        error && error !== '' &&
                                        <ServerMessage variant={'error'}>
                                            <ErrorMessageSectionItem key={key}>
                                                <DeviceError err={error} duplicateDeviceId={false}/> 
                                            </ErrorMessageSectionItem>
                                        </ServerMessage>
            
                                    )
                                })
                                }
                            </div>
                        }
                    </div>

                    {
                        deviceContext.existingDeviceDto?.sameTagIdLocation && deviceContext.existingDeviceDto.deviceDto?.status !== DEV_STATUS_INACTIVE &&
                        <div style={{width: '100%'}}>
                            {deviceContext.existingDeviceDto?.deviceDto &&
                                <>
                                    <ServerMessage variant={'error'}>
                                        <ErrorMessageSectionItem key={0}>
                                            <DeviceError err={' ' + getLocalizedString('createDevice.alreadyActive', "is already active.") + "\n"} linkError={"Device " + deviceContext.existingDeviceDto?.deviceDto?.deviceTag} duplicateDeviceId={true}/>
                                            <br/>
                                            <br/>
                                            <DeviceError err={getLocalizedString('createDevice.registerDiff', "To register a new device, use a different serial number and Device ID.")} duplicateDeviceId={false}/>
                                        </ErrorMessageSectionItem>
                                    </ServerMessage>
                                </>
                            }
                        </div>
                    }

                    <CreateExistingDevice
                        formDevice={formDevice}
                        createDeviceSuccessMessage={createDeviceSuccessMessage}
                        setCreateDeviceSuccessMessage={setCreateDeviceSuccessMessage}
                        uploadIndex={uploadIndex ?? undefined}
                        duplicateListUpdated={duplicateListUpdated}
                    />

                    <div className={'u-display--flex'}>

                        <IconButton id={'create-device-back-button'} color="primary" className={'back-button'} aria-label="back" onClick={() => {
                            goBack()
                        }}>
                            <ArrowBack className={'back-button-arrow'}/>
                        </IconButton>
                        <form id={'deviceForm'} onSubmit={submitForm} style={{width: '100%'}}>
                            <FormTitle variant='h5' className={'o-heading--xl'}>{getLocalizedString('createDevice.title', 'Register New Device')}</FormTitle>
                            {context.currentLocation?.serialIsDevice ?
                                createDeviceChildComponents
                                :
                                <>
                                    <div className={'createdevice-device-id-div'}>
                                        <ErrorWrapper {...createErrorProps(`${DeviceValidationKeys.deviceIdNumber}`)}>
                                            <div className={'editdevice-input-div'}>
                                                <ARCIdentifierInput
                                                    id={'add-device-device-id-input'}
                                                    size={'md'}
                                                    required={true} 
                                                    disabled={false}
                                                    value={formDevice.deviceIdNumber}
                                                    prefix={context.currentBrand?.deviceNumberPrefix}
                                                    label={getLocalizedString('createDevice.deviceIdNumber', 'Device ID')}
                                                    tooltip={deviceIdTooltip}
                                                    onChange={(value) => {
                                                        const error = lockDeviceIdNumber(value);
                                                        if (error) {
                                                            const showCharacter = !isAlphanumeric(value) && (fieldErrors[`${DeviceValidationKeys.deviceIdNumber}`]?.error?.length ?? 0) === 0;
                                                            if (showCharacter) {
                                                                setFormDevice({
                                                                    ...formDevice,
                                                                    deviceIdNumber: value.toUpperCase()
                                                                })
                                                            }
                                                            setFieldError(DeviceValidationKeys.deviceIdNumber, error)
                                                        } else {
                                                            clearFieldError(DeviceValidationKeys.deviceIdNumber)
                                                            setFormDevice({
                                                                ...formDevice,
                                                                deviceIdNumber: value.toUpperCase()
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </ErrorWrapper>
                                    </div>

                                    <div className={'createdevice-other-div'}>
                                        <div className={'createdevice-other-title-div'}>
                                            <div className={'createdevice-other-title'}>
                                                {getLocalizedString('createDevice.otherDetails', 'Other Details')}
                                            </div>
                                        </div>
                                        <ErrorWrapper {...createErrorProps(`${DeviceValidationKeys.deviceTag}`)}>
                                            <div className={'editdevice-input-div'}>
                                                <ARCIdentifierInput
                                                    id={'add-device-device-tag-input'}
                                                    size={'md'}
                                                    required={true} 
                                                    value={formDevice.deviceTag}
                                                    label={getLocalizedString('createDevice.deviceTag', 'Device Serial Number')}
                                                    tooltip={getLocalizedString('createDevice.deviceTag.tooltip', 'This is the unique identifier associated with the device.')}
                                                    onChange={(event) => {
                                                        const error = validateDeviceTag(event);
                                                        if (error) {
                                                            const showCharacter = !isAlphanumeric(event) && (fieldErrors[`${DeviceValidationKeys.deviceTag}`]?.error?.length ?? 0) === 0;
                                                            if (showCharacter) {
                                                                setFormDevice({
                                                                    ...formDevice,
                                                                    deviceTag: event
                                                                })
                                                            }
                                                            setFieldError(DeviceValidationKeys.deviceTag, error);
                                                        } else {
                                                            clearFieldError(DeviceValidationKeys.deviceTag);
                                                            setFormDevice({
                                                                ...formDevice,
                                                                deviceTag: event
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </ErrorWrapper>
                                        {(deviceTypeContext.deviceTypes && deviceTypeContext.deviceTypes.length > 0) &&
                                            <>
                                                <div className={'createdevice-device-type-title-div'}>
                                                    <div className={'createdevice-device-type-title'}>
                                                        {getLocalizedString('createDevice.deviceType', 'Device Type')}
                                                        <span className={'createdevice-red-star'}>
                                                    {'*'}
                                                </span>
                                                    </div>
                                                </div>
                                                <div className={'editdevice-input-div'}>
                                                    <ARCSelectInput
                                                        id={'add-device-device-type-input'}
                                                        size={'md'}
                                                        className={'createdevice-device-type-select'}
                                                        value={formDevice.deviceType?.deviceTypeId}
                                                        options={DeviceTypeOptions}
                                                        onChange={(option) => {
                                                            setFormDevice({
                                                                ...formDevice,
                                                                deviceType: option.value !== '' ? deviceTypeContext.deviceTypes.find(dType => dType.deviceTypeId === option.value) : undefined
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className={'createdevice-save-button-div'}>
                                        <ARCButton
                                            id="add-device-save-button"
                                            variant={'primary'}
                                            size={'md'}
                                            fill={'filled'}
                                            submit={true}
                                            onClick={() => {}}
                                            label={getLocalizedString('createDevice.submit', 'Save')}
                                        >
                                        </ARCButton>
                                    </div>
                                </>
                            }
                        </form>
                    </div>
                </div>
            </main>
        </AppWithFooter>
    )
}

